<template>
    <div>
        <p class="title">cohousing</p>    
        <p class="p1">Cohousing is een gemeenschap van particuliere woningen geclusterd rond gedeelde ruimtes. Elke geschakelde woning of unit heeft traditionele voorzieningen, waaronder een eigen keuken en badkamer. Gedeelde ruimtes bestaan meestal uit een gemeenschappelijk huis, dat een grotere keuken en eetkamer, wasruimte en recreatieruimtes bevat. Gedeelde buitenruimte kan een parkeerplaats, looppaden, open ruimte en een tuin omvatten. Binnen cohousing delen buren ook voorzieningen: dat kan gaan van wasmachines tot gereedschappen. Huishoudens hebben een onafhankelijk inkomen en privéleven, maar buren plannen en beheren samen gemeenschapsactiviteiten en de gedeelde ruimtes. De juridische structuur is typisch een vereniging van mede-eigenaren, een vereniging zonder winst, een woning&shy;coöperatie of een combinatie hiervan. Het doel van cohousing is het vergemakkelijken van de interactie tussen buren en biedt daardoor sociale, praktische, economische en ecologische voordelen.</p> 
        <p class="p1" style="margin-bottom: 10vh;">De term zelf is eind jaren zestig in Denemarken ontstaan. <i>Bofællesskab</i>  (letterlijk: leefgemeenschap) wordt via een omweg in de VS als cohousing geïntroduceerd in Vlaanderen. In Nederland spreekt men van <i>Centraal Wonen</i>. De term <i>gemeenschappelijk wonen</i> of <i>samenhuizen</i> is een overkoepelende term om verschillende gemeenschaps&shy;woonvormen samen te beschrijven. Cohousing onderscheidt zich hier vooral door de aanwezigheid van particuliere woonunits die op zich staan als huur- of koopwoning, wat niet het geval is bij vb. <i>coliving</i> of <i>gemeenschapshuizen</i>. Meer info over de verschillen vind je <a href="https://www.samenhuizen.be/nl/wat-is-samenhuizen" target="_blank" rel="noopener noreferrer">hier</a> terug.</p> 
    </div>     
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style></style>
